import { currentSemester, SEMESTER, SEMESTER_NAME } from "@cs124/person"
import { Metadata, validateMetadata } from "@cs124/shareable"
import { TabContext } from "@mui/lab"
import { Box, darken, lighten, Paper, Tab, Tabs, Typography } from "@mui/material"
import { readFile } from "fs/promises"
import { GetServerSideProps } from "next"
import React, { useEffect, useMemo, useState } from "react"
import { MainContainer, TopBar } from "~/components/layout/"
import { H1, P } from "~/components/material-ui"
import { A, LeadP } from "~/components/material-ui/"
import makeLightDark from "~/components/material-ui/makeLightDark"
import {
  DARK_DEFAULT_BACKGROUND_COLOR,
  DARK_PAPER_BACKGROUND_COLOR,
  LIGHT_DEFAULT_BACKGROUND_COLOR,
  LIGHT_PAPER_BACKGROUND_COLOR,
} from "~/components/material-ui/theme"
import { ShareableCode } from "~/components/shareable"
import { CURRENT_NUMBER } from "~/constants"
import addServerSideProps from "~/helpers/addServerSideProps"
import { SEO } from "~/layouts"

const startTime = new Date(currentSemester.start).valueOf()

const TimeRemaining: React.FC = () => {
  const [remaining, setRemaining] = useState<number | undefined>()
  useEffect(() => {
    const currentTime = new Date()
    if (currentTime.valueOf() > startTime) {
      return
    }
    const timer = setInterval(() => {
      setRemaining(Math.round((startTime - new Date().valueOf()) / 1000))
    }, 100)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <P>
      Our journey together begins
      {remaining > 0 ? " in " + (remaining >>> 0).toString(2) + " seconds. " : " now. "}
      <Typography variant="strong">Get excited!</Typography>
    </P>
  )
}

const IndexContent: React.FC<{
  javaCode: string
  javaStaticCode: string
  kotlinCode: string
  kotlinStaticCode: string
}> = ({ javaCode, javaStaticCode, kotlinCode, kotlinStaticCode }) => {
  const [tab, setTab] = useState("0")
  useEffect(() => {
    setTab(localStorage.getItem("homepage:tab") || "0")
  }, [])

  const handleChange = (_: React.SyntheticEvent<Element, Event>, newValue: number) => {
    localStorage.setItem("homepage:tab", newValue.toString())
    setTab(newValue.toString())
  }

  const kotlinMetadata = useMemo(() => {
    const m = Metadata.check({
      type: "metadata",
      number: CURRENT_NUMBER,
      semester: SEMESTER,
      path: `index/${SEMESTER}/example-kotlin`,
      sitePath: "/",
      language: "kotlin",
      contentType: "walkthrough",
      code: kotlinCode,
      open: true,
      instructions: `Greet the ${SEMESTER_NAME} students in Kotlin!`,
      disabled: false,
    })
    validateMetadata(m)
    return m
  }, [kotlinCode])

  const javaMetadata = useMemo(() => {
    const m = Metadata.check({
      type: "metadata",
      number: CURRENT_NUMBER,
      semester: SEMESTER,
      path: `index/${SEMESTER}/example-java`,
      sitePath: "/",
      language: "java",
      contentType: "walkthrough",
      code: javaCode,
      open: true,
      instructions: `Greet the ${SEMESTER_NAME} students in Java!`,
      disabled: false,
    })
    validateMetadata(m)
    return m
  }, [javaCode])

  return (
    <TabContext value={tab}>
      <Box
        sx={{
          ...makeLightDark(
            { backgroundColor: LIGHT_DEFAULT_BACKGROUND_COLOR, opacity: 0.9 },
            { backgroundColor: DARK_DEFAULT_BACKGROUND_COLOR, opacity: 0.95 }
          ),
        }}
      >
        <Paper square>
          <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleChange}>
            <Tab value={"0"} label="Learn Kotlin" />
            <Tab value={"1"} label="Learn Java" />
            <Tab value={"2"} label="Calendar" />
          </Tabs>
        </Paper>
        <Box hidden={tab !== "0"}>
          <ShareableCode
            metadata={JSON.stringify(kotlinMetadata)}
            staticCode={kotlinStaticCode}
            preloads={["challen@illinois.edu"]}
            noProgress
          >
            <P>Greet the {SEMESTER_NAME} students in Kotlin!</P>
          </ShareableCode>
        </Box>
        <Box hidden={tab !== "1"}>
          <ShareableCode
            metadata={JSON.stringify(javaMetadata)}
            staticCode={javaStaticCode}
            preloads={["challen@illinois.edu"]}
            noProgress
          >
            <P>Greet the {SEMESTER_NAME} students in Java!</P>
          </ShareableCode>
        </Box>
        <Box hidden={tab !== "2"}>
          <Box
            component="iframe"
            sx={{ width: "100%", height: "400px" }}
            src="https://calendar.google.com/calendar/embed?showTitle=0&amp;showPrint=0&amp;mode=WEEK&amp;height=600&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=c_9de9f2c09208dd3f811263a589c52789dbb9109645086c49b275bfae5e682dea@group.calendar.google.com&amp;color=%23B1440E&amp;ctz=America%2FChicago"
          />
        </Box>
        <Paper square sx={{ padding: 2 }}>
          <H1>Learn Computer Science at Illinois</H1>
          <P>
            CS 124 will teach you the basics of computer science and programming. Once you know these things, you can
            and <Typography component="em">will</Typography> change the world.
          </P>
          <P>
            CS 124 online is an innovative, immersive, interactive online experience. Try experimenting with the code
            above to begin your journey in computer science! Note that we offer CS 124 in{" "}
            <A href="/choose/">
              <Typography component="em">both</Typography> Kotlin and Java.
            </A>
          </P>
          <TimeRemaining />
          <LeadP>
            <Box component="strong">
              Ready to go? Start <A href="/start/">here</A>.
            </Box>
          </LeadP>
        </Paper>
      </Box>
    </TabContext>
  )
}

const Index: React.FC<{
  javaCode: string
  javaStaticCode: string
  kotlinCode: string
  kotlinStaticCode: string
}> = props => {
  return (
    <>
      <SEO title="An Introduction to Computer Science" description="An introduction to computer science" />
      <TopBar
        sx={{
          ...makeLightDark(
            {
              backgroundColor: darken(LIGHT_PAPER_BACKGROUND_COLOR, 0.2),
            },
            {
              backgroundColor: lighten(DARK_PAPER_BACKGROUND_COLOR, 0.4),
            }
          ),
        }}
      />

      <MainContainer maxWidth="md">
        <IndexContent {...props} />
      </MainContainer>
      <Box
        sx={{
          zIndex: -1,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "url(/images/pages/background.png) center center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      />
    </>
  )
}
export default Index

export const getServerSideProps: GetServerSideProps = async context => {
  const javaCode = Buffer.from((await readFile("metadata/greeting.java")).toString().trim()).toString()
  const kotlinCode = Buffer.from((await readFile("metadata/greeting.kt")).toString().trim()).toString()
  const javaStaticCode = Buffer.from((await readFile("metadata/greeting.java.html")).toString().trim()).toString()
  const kotlinStaticCode = Buffer.from((await readFile("metadata/greeting.kt.html")).toString().trim()).toString()

  const cachedCode = {
    javaCode: Buffer.from(javaCode).toString("base64"),
    javaStaticCode: Buffer.from(javaStaticCode).toString("base64"),
    kotlinCode: Buffer.from(kotlinCode).toString("base64"),
    kotlinStaticCode: Buffer.from(kotlinStaticCode).toString("base64"),
  }

  return {
    props: {
      ...(await addServerSideProps(context)),
      ...cachedCode,
    },
  }
}
