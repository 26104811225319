import { Typography } from "@mui/material"
import { Parser, ProcessNodeDefinitions } from "html-to-react"
import React, { useMemo } from "react"
import Children from "react-children-utilities"
import { Ace } from "../ace/"
import { A, Code } from "../material-ui/"

const processNodeDefinitions = ProcessNodeDefinitions()
const processingInstructions = [
  {
    shouldProcessNode: function (node) {
      return node.name === "p"
    },
    processNode: function (node, children, index) {
      return (
        <Typography key={index} paragraph={true}>
          {children}
        </Typography>
      )
    },
  },
  {
    shouldProcessNode: function (node) {
      return node.name === "ol"
    },
    processNode: function (node, children, index) {
      return (
        <Typography key={index} component={"ol"} paragraph={true}>
          {children}
        </Typography>
      )
    },
  },
  {
    shouldProcessNode: function (node) {
      return node.name === "ul"
    },
    processNode: function (node, children, index) {
      return (
        <Typography key={index} component={"ul"} paragraph={true}>
          {children}
        </Typography>
      )
    },
  },
  {
    shouldProcessNode: function (node) {
      return ["h1", "h2", "h3", "h4", "h5", "h6"].includes(node.name)
    },
    processNode: function (node, children, index) {
      return (
        <Typography key={index} variant={node.name} gutterBottom={true}>
          {children}
        </Typography>
      )
    },
  },
  {
    shouldProcessNode: function (node) {
      return node.name === "a"
    },
    processNode: function (node, children, index) {
      return (
        <A key={index} href={node.attribs["href"]}>
          {children}
        </A>
      )
    },
  },
  {
    shouldProcessNode: function (node) {
      return node.name === "pre"
    },
    processNode: function (_node, children, index) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const childs = React.Children.toArray(children) as any[]
      if (childs.length === 1 && childs[0].type === "code") {
        let language
        try {
          if (childs[0].props.className.startsWith("language-")) {
            language = childs[0].props.className.split("-")[1]
          }
        } catch (err) {}
        if (language === "java" || language === "kotlin") {
          const code = Buffer.from(Children.onlyText(children).trim()).toString("base64")
          return (
            <Ace
              key={index}
              mode={language}
              displayOnly
              noJeed
              numbers={"true"}
              quizMode
              maxLines={Infinity}
              code={code}
            />
          )
        }
      }
      return (
        <Typography variant="pre" key={index}>
          {children}
        </Typography>
      )
    },
  },
  {
    shouldProcessNode: function (node) {
      return node.name === "code" && node.parent?.name !== "pre"
    },
    processNode: function (node, children, index) {
      return <Code key={index}>{children}</Code>
    },
  },
  {
    shouldProcessNode: function () {
      return true
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
]

export const Converter: React.FC<{ html: string }> = ({ html }) => {
  const element = useMemo(() => {
    const parser = Parser()
    return parser.parseWithInstructions(html, () => true, processingInstructions)
  }, [html])
  return <>{element}</>
}
