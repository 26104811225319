import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link"
import Typography, { TypographyProps } from "@mui/material/Typography"
import NextLink, { LinkProps as NextLinkProps } from "next/link"
import React, { CSSProperties, forwardRef, PropsWithChildren } from "react"
import makeLightDark from "./makeLightDark"
import { DARK_DEFAULT_TEXT_COLOR, LIGHT_DEFAULT_TEXT_COLOR } from "./theme"

const Heading = (tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"): React.FC<TypographyProps> => {
  const WrappedHeading: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
      <Typography variant={tag} gutterBottom={true} {...props}>
        {children}
      </Typography>
    )
  }
  return WrappedHeading
}

export const H1 = Heading("h1")
export const H2 = Heading("h2")
export const H3 = Heading("h3")
export const H4 = Heading("h4")
export const H5 = Heading("h5")
export const H6 = Heading("h6")

export const headings = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
}

export const P: React.FC<TypographyProps> = ({ children, ...props }) => (
  <Typography paragraph={true} {...props}>
    {children}
  </Typography>
)

export const LeadP: React.FC<PropsWithChildren<unknown>> = ({ children, ...props }) => {
  return (
    <Typography variant="leadP" {...props}>
      {children}
    </Typography>
  )
}

export const ListWrapper: React.FC<PropsWithChildren<TypographyProps> & { type: "ul" | "ol" }> = ({
  type,
  ...props
}) => <Typography paragraph={true} component={type === "ul" ? "ul" : "ol"} {...props} />

export const Code: React.FC<React.HTMLProps<HTMLSpanElement>> = ({ children, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ref, ...other } = props
  return (
    <Typography variant="code" {...other}>
      {children}
    </Typography>
  )
}

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href" | "onClick" | "onMouseEnter" | "onTouchStart">,
    Omit<NextLinkProps, "href" | "as"> {
  to: NextLinkProps["href"]
  linkAs?: NextLinkProps["as"]
  href?: NextLinkProps["href"]
}

export const NextLinkComposed = forwardRef<HTMLAnchorElement, NextLinkComposedProps>(
  function NextLinkComposed(props, ref) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { to, linkAs, href, replace, scroll, prefetch, locale, shallow, passHref, ...other } = props
    if (to.toString().startsWith("#")) {
      return (
        <a
          onClick={e => {
            e.preventDefault()
            window.location.href = `${window.location.href.split("#")[0]}${to.toString()}`
          }}
          href={to.toString()}
          {...other}
        />
      )
    }
    return (
      <NextLink
        href={to}
        prefetch={prefetch}
        as={linkAs}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        locale={locale}
        passHref={passHref}
        ref={ref}
        {...other}
      />
    )
  }
)

export type LinkProps = {
  as?: NextLinkProps["as"]
  href: NextLinkProps["href"]
  linkAs?: NextLinkProps["as"] // Useful when the as prop is shallow by styled().
  forceExternal?: boolean
  forceInSame?: boolean
  sidebar?: boolean
  noDecoration?: boolean
} & Omit<NextLinkComposedProps, "to" | "linkAs" | "href"> &
  Omit<MuiLinkProps, "href">

export const A = forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  const {
    as: linkAs,
    href,
    children,
    forceExternal,
    forceInSame,
    sidebar,
    noDecoration,
    sx: originalSX,
    ...other
  } = props
  const pathname = typeof href === "string" ? href : href.pathname

  const isExternal =
    forceExternal ||
    (!forceInSame && typeof href === "string" && (href.indexOf("http") === 0 || href.indexOf("mailto:") === 0))

  const download = !isExternal && typeof href === "string" && (href.endsWith(".java") || href.endsWith(".kt"))

  const sx: CSSProperties = {
    ...originalSX,
    ...(sidebar && makeLightDark({ color: LIGHT_DEFAULT_TEXT_COLOR }, { color: DARK_DEFAULT_TEXT_COLOR })),
    ...(noDecoration && {
      textDecoration: "none",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any
  if (noDecoration) {
    other.underline = "none"
  }

  if (isExternal) {
    return (
      <MuiLink target={"_blank"} rel={"noopener"} href={pathname} ref={ref} sx={sx} {...other}>
        <>
          {children}
          <OpenInNewIcon sx={{ fontSize: "0.9em", marginLeft: "0.1em", marginBottom: "-0.2em" }} />
        </>
      </MuiLink>
    )
  } else if (forceInSame) {
    return (
      <MuiLink target={"_self"} rel={"noopener"} href={pathname} ref={ref} sx={sx} {...other}>
        {children}
      </MuiLink>
    )
  } else {
    if (download) {
      other.target = "_blank"
    }
    return (
      <MuiLink download={download} component={NextLinkComposed} linkAs={linkAs} ref={ref} to={href} sx={sx} {...other}>
        {children}
      </MuiLink>
    )
  }
})
