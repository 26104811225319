/* eslint-disable @typescript-eslint/no-empty-object-type */
import {
  PaletteColorOptions,
  createTheme,
  experimental_extendTheme as extendTheme,
  responsiveFontSizes,
} from "@mui/material/styles"
import type {} from "@mui/material/themeCssVarsAugmentation"

import { grey } from "@mui/material/colors"
import { Source_Code_Pro as Code, Montserrat as Headings, Noto_Sans_Display as Text } from "next/font/google"
import { CSSProperties } from "react"
import makeLightDark from "./makeLightDark"

const text = Text({ subsets: ["latin"] })
const headings = Headings({ subsets: ["latin"] })
const code = Code({ subsets: ["latin"] })

declare module "@mui/material/styles" {
  interface ExtendedTheme {
    gap: (spacing: number) => number
  }
  interface ExtendedCssVarsTheme {
    gap: (spacing: number) => number
  }
  interface Theme extends ExtendedTheme {}
  interface ThemeOptions extends ExtendedTheme {}
  interface CssVarsTheme extends ExtendedCssVarsTheme {}
  interface TypographyVariants {
    pre: CSSProperties
    kbd: CSSProperties
    code: CSSProperties
    leadP: CSSProperties
    strong: CSSProperties
    em: CSSProperties
  }
  interface TypographyVariantsOptions {
    pre?: CSSProperties
    kbd?: CSSProperties
    code?: CSSProperties
    leadP?: CSSProperties
    strong?: CSSProperties
    em?: CSSProperties
  }
  interface Palette {
    kotlin: PaletteColorOptions
    java: PaletteColorOptions
    disabled: PaletteColorOptions
  }
  interface PaletteOptions {
    kotlin: PaletteColorOptions
    java: PaletteColorOptions
    disabled: PaletteColorOptions
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    pre: true
    kbd: true
    code: true
    leadP: true
    strong: true
    em: true
  }
}
declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    kotlin: true
    java: true
    disabled: true
  }
}

export const LIGHT_DEFAULT_BACKGROUND_COLOR = "#FBFBFB"
export const DARK_DEFAULT_BACKGROUND_COLOR = "#303030"
export const LIGHT_PAPER_BACKGROUND_COLOR = "#FCFCFC"
export const DARK_PAPER_BACKGROUND_COLOR = "#303030"
export const LIGHT_DEFAULT_TEXT_COLOR = "#555555"
export const DARK_DEFAULT_TEXT_COLOR = "#BBBBBB"

const LIGHT_PRIMARY_MAIN = "#13294b"
const DARK_PRIMARY_MAIN = "#92a8ca"
const LIGHT_SECONDARY_MAIN = "#ff9900"
const DARK_SECONDARY_MAIN = "#ff8c00"

const headingFontFamily = [headings.style.fontFamily, "serif"].join(",")
const defaultTheme = createTheme()

const baseTheme = responsiveFontSizes(
  createTheme({
    gap: (spacing: number) => parseInt(defaultTheme.spacing(spacing), 10),
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: [text.style.fontFamily, "sans-serif"].join(","),
      fontSize: 18,
      body1: {
        lineHeight: 1.5,
      },
      body2: {
        lineHeight: 1.5,
      },
      caption: {
        lineHeight: 1.4,
      },
      h1: {
        fontFamily: headingFontFamily,
        fontSize: "3rem",
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h2: {
        fontFamily: headingFontFamily,
        fontSize: "2.5rem",
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h3: {
        fontFamily: headingFontFamily,
        fontSize: "2rem",
        fontWeight: 700,
      },
      h4: {
        fontFamily: headingFontFamily,
        fontSize: "1.25rem",
        fontWeight: 700,
      },
      h5: {
        fontFamily: headingFontFamily,
        fontSize: "1rem",
        fontWeight: 700,
      },
      h6: {
        fontFamily: headingFontFamily,
        fontSize: "1rem",
        fontWeight: 400,
        textDecoration: "underline",
      },
      pre: {
        fontFamily: `${code.style.fontFamily}, monospace;`,
        fontSize: "16px",
      },
      code: {
        fontFamily: `${code.style.fontFamily}, monospace;`,
      },
      kbd: {
        fontFamily: `${code.style.fontFamily}, monospace;`,
      },
      strong: {
        fontWeight: 700,
      },
      em: {
        fontStyle: "italic",
      },
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            marginTop: "1rem",
            marginBottom: "1rem",
          },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontWeight: 700,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            WebkitFontSmoothing: "auto",
          },
          code: {
            fontFamily: `${code.style.fontFamily}, monospace;`,
          },
          kbd: {
            fontFamily: `${code.style.fontFamily}, monospace;`,
          },
          ".ace_editor": {
            fontFamily: `${code.style.fontFamily}, monospace;`,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            positionFixed: true,
            ...makeLightDark(
              {
                color: "#555555",
                background: LIGHT_DEFAULT_BACKGROUND_COLOR,
              },
              {
                color: "#BBBBBB",
                background: DARK_DEFAULT_BACKGROUND_COLOR,
              }
            ),
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "hover",
        },
        styleOverrides: {
          root: {
            ...makeLightDark({ color: LIGHT_SECONDARY_MAIN }, { color: DARK_PRIMARY_MAIN }),
          },
        },
      },
      MuiSwitch: {
        defaultProps: {
          color: "secondary",
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: "secondary",
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            pre: "pre",
            kbd: "kbd",
            code: "code",
            leadP: "p",
            strong: "span",
            em: "span",
          },
        },
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(ownerState.variant === "code" &&
              makeLightDark({ color: LIGHT_SECONDARY_MAIN }, { color: DARK_PRIMARY_MAIN })),
            ...(ownerState.variant === "leadP" && {
              marginBottom: theme.spacing(3),
              fontSize: "1.4em",
              lineHeight: 1.4,
            }),
          }),
        },
      },
    },
  })
)

export const theme = extendTheme({
  ...baseTheme,
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: LIGHT_PRIMARY_MAIN,
        },
        secondary: {
          main: LIGHT_SECONDARY_MAIN,
        },
        text: {
          primary: LIGHT_DEFAULT_TEXT_COLOR,
        },
        background: {
          default: LIGHT_DEFAULT_BACKGROUND_COLOR,
          paper: LIGHT_PAPER_BACKGROUND_COLOR,
        },
        java: {
          main: "lightblue",
          contrastText: LIGHT_DEFAULT_TEXT_COLOR,
        },
        kotlin: {
          main: "lightgreen",
          contrastText: LIGHT_DEFAULT_TEXT_COLOR,
        },
        disabled: {
          main: grey[300],
          contrastText: LIGHT_DEFAULT_TEXT_COLOR,
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: DARK_PRIMARY_MAIN,
        },
        secondary: {
          main: DARK_SECONDARY_MAIN,
        },
        text: {
          primary: DARK_DEFAULT_TEXT_COLOR,
        },
        background: {
          default: DARK_DEFAULT_BACKGROUND_COLOR,
          paper: DARK_PAPER_BACKGROUND_COLOR,
        },
        java: {
          main: "darkblue",
          contrastText: DARK_DEFAULT_TEXT_COLOR,
        },
        kotlin: {
          main: "darkgreen",
          contrastText: DARK_DEFAULT_TEXT_COLOR,
        },
        disabled: {
          main: grey[700],
          contrastText: DARK_DEFAULT_TEXT_COLOR,
        },
      },
    },
  },
})
